




















































import { Component, Prop, Vue, Provide, Mixins } from 'vue-property-decorator';
import { accountRegister } from '@/apis/account.api';
import {
  errorPopupHandler,
  errorCatchHandler,
  loadings,
} from '@/utils/decorators/VueTimmer';
import FormMixin from '@/mixins/form';
import { MessageBox } from 'element-ui';

@Component
export default class RegisterPage extends Mixins(FormMixin) {
  dialogVisible = false;

  @Provide()
  form = {
    email: '',
    password: '',
    checked: false,
  };

  errors = {
    register: '',
  };

  loadings = {
    register: false,
  };

  @errorCatchHandler('errors.register', 'Fail to register', 'form')
  @loadings('loadings.register')
  onSubmit() {
    if (!this.form.checked || !this.isFormValid) {
      return;
    }

    return accountRegister({
      ...this.form,
      company_name: '123',
    }).then(() => {
      this.$router.replace({
        name: 'register_verify',
        query: {
          email: this.form.email,
        },
      });
    });
  }

  onReset() {}
}
